import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page Not Found" />
    <div class='wrapper four-o-four'>
        <h1>Page Not Found</h1>
        <p>Sorry, we can’t find the page you were looking for. The page may no longer exist or it may have moved.</p>
        <p>Sorry for the inconvenience!</p>
    </div>
  </Layout>
)

export default NotFoundPage
